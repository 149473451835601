import * as React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { AnyRoute } from "./AnyRoute";

export type RouteItem = {
  path: string;
  routePath: (args?: any) => string;
  routeComponent: (props: React.ComponentProps<typeof Route>) => JSX.Element;
  component: any;
  exact?: boolean;
};

export const routes: Record<string, RouteItem> = {
  home: {
    path: "/",
    routePath: () => "/",
    routeComponent: AnyRoute,
    component: React.lazy(() => import("./pages/Home/Home")),
    exact: true,
  },
  signIn: {
    path: "/signIn",
    routePath: () => "/signIn",
    routeComponent: PublicRoute,
    component: React.lazy(() => import("./pages/SignIn/SignIn")),
  },
  comunicados: {
    path: "/comunicados",
    routePath: () => "/comunicados",
    routeComponent: PrivateRoute,
    component: React.lazy(() => import("./pages/Comunicados/Comunicados")),
  },
  nuevoComunicado: {
    path: "/nuevo_comunicado",
    routePath: () => "/nuevo_comunicado",
    routeComponent: PrivateRoute,
    component: React.lazy(() => import("./pages/Comunicados/NuevoComunicado")),
  },
  comunicado: {
    path: "/m/:comunicado",
    routePath: () => "/m/:comunicado",
    routeComponent: AnyRoute,
    component: React.lazy(() => import("./pages/Comunicados/ShowComunicado")),
  },
  comunicadoAlias: {
    path: "/comunicado/:comunicado",
    routePath: () => "/comunicado/:comunicado",
    routeComponent: AnyRoute,
    component: React.lazy(() => import("./pages/Comunicados/ShowComunicado")),
  },
  // nuevaVotacion: {
  //   path: "/nueva_consulta",
  //   routePath: () => "/nueva_consulta",
  //   routeComponent: PrivateRoute,
  //   component: React.lazy(() => import("./pages/Votacion/NuevaConsulta")),
  // },
  votaciones: {
    path: "/votaciones",
    routePath: () => "/votaciones",
    routeComponent: PrivateRoute,
    component: React.lazy(() => import("./pages/Votacion/Votaciones")),
  },
  comunidad: {
    path: "/comunidad",
    routePath: () => "/comunidad",
    routeComponent: PrivateRoute,
    component: React.lazy(() => import("./pages/Comunidad")),
  },
  votacion: {
    path: "/votacion/:votacionId",
    routePath: () => "/votacion/:votacionId",
    routeComponent: PrivateRoute,
    component: React.lazy(() => import("./pages/Votacion/ShowVotacion")),
  },
  verify: {
    path: "/verify",
    routePath: () => "/verify",
    routeComponent: PublicRoute,
    component: React.lazy(
      () => import("./pages/VerifyMagicLink/VerifyMagicLink")
    ),
  },
};

export const renderRoutes = Object.entries(routes);
