import { UserType } from "../pages/Home/Home";
import { Button, PageHeader } from "antd";
import React from "react";
import RenderMenu from "./RenderMenu";

function Header(props: {
  user: UserType;
  subTitle: string;
  onClick: () => Promise<void>;
  loggedIn: boolean;
}) {
  if (!props.loggedIn) {
    return null;
  }
  return (
    <div>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader
          ghost={false}
          title="Comunidad Fundo Alhue"
          subTitle={props.subTitle}
          extra={[
            <Button key="salir" type="primary" onClick={props.onClick}>
              Salir
            </Button>,
          ]}
        ></PageHeader>
      </div>

      <RenderMenu user={props.user} />
    </div>
  );
}

export default Header;
