import { UserType } from "../pages/Home/Home";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { routes } from "../Routes";

function RenderMenu({ user }: { user: UserType }) {
  const history = useHistory();
  const location = useLocation();
  const [current, setCurrent] = useState<string>("nothing");

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.includes("votacion")) {
        setCurrent("votaciones");
      }
      if (location.pathname.includes("comunidad")) {
        setCurrent("comunidad");
      }
    }
  }, [location.pathname]);

  const handleClick = (e: { key: React.SetStateAction<string> }) => {
    if (e.key) {
      setCurrent(e.key);
    }
  };

  if (user.loading || user.parcela === 0) {
    return null;
  }

  return (
    <div>
      <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item
          key="votaciones"
          onClick={() => {
            history.replace(routes.votaciones.routePath());
          }}
        >
          Votaciones
        </Menu.Item>
        <Menu.Item
          key={"comunidad"}
          onClick={() => {
            history.replace(routes.comunidad.routePath());
          }}
        >
          Listado vecinos
        </Menu.Item>
        <Menu.Item
          key={"comunicados"}
          onClick={() => {
            history.replace(routes.comunicados.routePath());
          }}
        >
          Mensajes
        </Menu.Item>
        {user.parcela === 7 && user.etapa === 1 && (
          <>
            <Menu.Item key={"medidores"} disabled>
              Lectura Medidores
            </Menu.Item>
            <Menu.Item key={"ggcc"} disabled>
              Gastos Comunes
            </Menu.Item>
          </>
        )}
      </Menu>
    </div>
  );
}

export default RenderMenu;
