import React, { useCallback, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { AuthProvider, useAuth } from "./Auth";
import { Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { renderRoutes } from "./Routes";
import { Col, message, Row } from "antd";
import { UserType } from "./pages/Home/Home";
import { Auth } from "aws-amplify";
import Header from "./components/Header";
import { getRoles } from "./Roles";

const MainRender = () => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [user, setUser] = React.useState<UserType>({
    email: "",
    parcela: 0,
    etapa: 0,
    loading: true,
    roles: [],
  });
  const [subtitle, setSubtitle] = React.useState("");
  const { signOut } = useAuth();

  const userSignOut = useCallback(async () => {
    try {
      signOut().then(() => {
        setLoggedIn(false);
      });
    } catch (e: any) {
      message.error(e.message);
    }
  }, [signOut]);

  useEffect(() => {
    Auth.currentUserInfo()
      .then((info) => {
        const attributes = info.attributes;
        const parcela = parseInt(attributes["custom:parcela"] || "0", 10);
        const etapa = parseInt(attributes["custom:etapa"] || "0", 10);
        setUser({
          email: info.username,
          parcela,
          etapa,
          loading: false,
          roles: getRoles(parcela, etapa),
        });
        setSubtitle(`Hola Parcela ${parcela} Etapa ${etapa}`);
        setLoggedIn(true);
      })
      .catch((reason) => {
        // userSignOut().then(() => {
        //   console.log("Exit");
        // });
      });
  }, [loggedIn]);

  return (
    <div>
      <BrowserRouter>
        <Header
          user={user}
          subTitle={subtitle}
          onClick={userSignOut}
          loggedIn={loggedIn}
        />

        <Switch>
          <Suspense fallback={<div />}>
            {renderRoutes.map(([key, value]) => (
              <value.routeComponent
                key={key}
                path={value.path}
                exact={value?.exact}
              >
                <value.component setLoggedIn={setLoggedIn} user={user} />
              </value.routeComponent>
            ))}
          </Suspense>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

function App() {
  return (
    <Row>
      <Col span={20} offset={2}>
        <AuthProvider>
          <MainRender />
        </AuthProvider>
      </Col>
    </Row>
  );
}

export default App;
