import axios from "axios";
import { Auth } from "aws-amplify";

export const requestMagicLink = async (email: string) => {
  const res = await axios.post(process.env.REACT_APP_AUTH_API_ENDPOINT || "", {
    email,
  });
  return res.data;
};

async function getAccessToken() {
  const currentSession = await Auth.currentSession();
  return currentSession.getAccessToken().getJwtToken();
}

type HeaderType = {
  Accept: string;
  Authorization?: string;
};

type OptionsType = {
  method: string;
  headers: HeaderType;
  body?: string;
};

async function getOptions(method: string, data?: object) {
  const options: OptionsType = {
    method: method,
    headers: {
      Accept: "application/json",
    },
  };
  try {
    const accessToken = await getAccessToken();
    options.headers.Authorization = `Bearer ${accessToken}`;
  } catch (error) {
    //user not logged in, fallback to request without auth
  }
  if (data && method === "POST") {
    options.body = JSON.stringify(data);
  }
  return options;
}

export const apiGet = (operation: string) => {
  return getOptions("GET").then((options) => {
    const endpoint = options.headers.Authorization
      ? process.env.REACT_APP_PRIVATE_API_ENDPOINT
      : process.env.REACT_APP_AUTH_API_ENDPOINT;
    return fetch(endpoint + operation, options).then((o) => {
      return o.json();
    });
  });
};

export const apiPost = (operation: string, data: any) => {
  return getOptions("POST", data).then((options) => {
    return fetch(
      process.env.REACT_APP_PRIVATE_API_ENDPOINT + operation,
      options
    ).then((o) => {
      if (o.status >= 200 && o.status < 300) {
        return o.json();
      }
      console.error(o);
      throw new Error(o.statusText);
    });
  });
};

export const apiGetSensoresData = async () => {
  return fetch(
    process.env.REACT_APP_SENSORES_API_ENDPOINT + "get-datos-sensores"
  ).then((data) => {
    if (data.status === 200) {
      return data.json();
    }
    console.error(data);
    throw new Error(data.statusText);
  });
};
